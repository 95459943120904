.info-alert {
    $px: 2rem;
    $py: .8rem;
    @extend .rounded;
    @extend .section-text;
    background-color: $teal-light;
    padding: $py $px;
    & .alert-title {
        @include back-color();
        @extend .rounded;
        @extend h3;
        padding: .5rem ($px - $py);
        margin-right: -($px - $py);
        margin-left: -($px - $py);
    }
}
