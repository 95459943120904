.about {
    @extend .container;
    @extend .px-xxl-0;
    margin-bottom: 4rem;
    &__row {
        @extend .row;
        align-items: center;
    }
    &__col {
        @extend .col-12;
        @extend .col-lg-6;
    }
    & .section-title {
        line-height: 1.2;
    }
    &-image {
        display: block;
        @extend .mx-auto;
    }
    @include media-breakpoint-up(xxl) {
        & .section-title {
            font-size: 2.8rem;
            margin-bottom: 4rem;
        }
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 2rem;
        &-image {
            width: 400px;
        }
    }
}
