.bootstrap-select {
    & .dropdown-item {
        white-space: normal;
    }
    & .dropdown-toggle:focus,
    &>select.mobile-device:focus+.dropdown-toggle {
        outline: 0 !important;
    }
    & .no-results {
        background-color: transparent;
    }
    & .dropdown-menu {
        border-color: $input-border-color;
        & div.inner {
            @extend %scrollbar;
        }
    }
    &.-sm {
        & .dropdown-menu {
            font-size: $input-font-size-sm;
        }
    }
}
