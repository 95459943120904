.account-nav {
    @include back-color();
    font-size: $small-font-size;
    overflow: hidden;
    & .nav {
        flex-wrap: nowrap;
        &-link {
            color: inherit;
            &:hover {
                opacity: .8;
            }
            &.active {
                color: $primary;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        font-size: $font-size-sm;
    }
}
