.contacts {
    &__map {
        @include media-breakpoint-up(md) {
            margin-top: 2.5rem;
            border-radius: $border-radius;
            overflow: hidden;
        }
        @include media-breakpoint-down(md) {
            margin-left: -$grid-gutter-width * .5;
            margin-right: -$grid-gutter-width * .5;
            margin-top: 2rem;
        }
    }
}
