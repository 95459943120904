.comments {
    margin-top: 3rem;
    padding-top: 1.5rem;
    border-top: 1px dashed $primary;
    &__history {
        @extend .col-12;
        @extend .col-lg-6;
        margin-bottom: 2rem;
    }
    &__form {
        @extend .col-12;
        @extend .col-lg-6;
        @extend .order-lg-1;
        margin-bottom: 2rem;
        & label {
            font-weight: $font-weight-bold;
        }
        & textarea {
            height: 90px;
        }
        & .btn {
            @extend .btn-secondary;
        }
        @include media-breakpoint-up(sm) {
            &-layout {
                border-radius: $border-radius;
                background-color: rgba($primary, .2);
                padding: 1.5rem;
            }
        }
    }
}

.comment {
    margin-bottom: 1.5rem;
    &-list {
        display: flex;
        flex-direction: column;
    }
    &-author {
        color: $gray-600;
        font-size: $small-font-size;
        margin-bottom: .25rem;
    }
    &-message {
        display: inline-block;
        background-color: $light;
        text-align: left;
        border: 1px solid $gray-300;
        padding: .5rem 1rem;
        border-radius: $border-radius-sm;
        max-width: 90%;
    }
    &-data {
        color: $gray-600;
        font-size: $small-font-size;
        margin-top: .25rem;
    }
    &.my-comment {
        text-align: right;
        & .comment-message {
            background-color: $white;
            @include gradient-y(rgba($primary, .05), rgba($primary, .2));
            border-color: lighten(desaturate($primary, 40%), 10%);
        }
    }
}
