.goals {
    @extend .board;
    @extend %section-margin;
    &__body {
        @extend .row;
        margin-bottom: 1.5rem;
    }
    &__item {
        @extend .col-12;
        @extend .col-sm;
    }
    @include media-breakpoint-up(md) {
        &__body {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }
    @include media-breakpoint-up(xl) {
        &__body {
            margin-top: 2.5rem;
            margin-bottom: 3rem;
        }
        &__item {
            &:first-child {
                .goal-card {
                    border-right: 1px solid $gray-300;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        @include padding-y(2rem);
    }
}

.goal {
    &-card {
        position: relative;
        overflow: hidden;
        height: 280px;
        margin-bottom: 2rem;
    }
    &-text {
        position: relative;
        @extend .section-text;
        @extend .pt-xl-2;
        max-width: 250px;
        z-index: 2;
    }
    &-picture {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 60%;
    }
    @include media-breakpoint-up(xl) {
        &-picture {
            right: 2.5rem;
        }
    }
    @include media-breakpoint-between(sm,lg) {
        &-picture {
            max-width: 200px;
        }
    }
}
