.nav-tabs {
    & .nav-link {
        color: $gray-600;
        &:hover {
            color: $body-color;
        }
    }
    @include media-breakpoint-up(xl) {
        font-size: 1.1rem;
    }
}

.nav-overflow {
    $gap: $grid-gutter-width * .5;
    position: relative;
    margin-left: -$gap;
    margin-right: -$gap;
    margin-bottom: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 2;
    & .nav-tabs {
        display: inline-flex;
        flex-wrap: nowrap;
        margin-left: $gap;
        margin-right: $gap;
        &:before {
            content: '';
            display: block;
            border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
            position: absolute;
            left: $gap;
            right: $gap;
            bottom: 0;
            z-index: -1;
        }
        & .nav-link {
            white-space: nowrap;
        }
    }

    @include media-breakpoint-down(xl) {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.nav-pills {
    flex-direction: column;
    & .nav-link {
        margin-bottom: .5rem;
        &:not(.active):hover {
            background-color: $teal-light;
        }
    }
}
