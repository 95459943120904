.expert {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    @extend .mb-lg-6;
    &-photo {
        flex: 0 0 auto;
        width: 230px;
        border-radius: $border-radius-sm;
        @extend .mb-4;
        @extend .mb-sm-0;
        @extend .me-sm-5;
    }
    &-body {
        flex: 1 0 0%;
    }
    &-name {
        @extend h4;
        margin-bottom: .5rem;
    }
    &-contact {
        margin-top: 1.5rem;
        & p {
            margin-bottom: .3rem;
        }
    }
    @include media-breakpoint-up(sm) {
        flex-direction: row;
        align-items: center;
        &-photo {
            max-width: 40%;
        }
    }
}
