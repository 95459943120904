.login {
    text-align: center;
    font-size: $font-size-sm;
    @include gradient-y(rgba($teal, .24), transparent);
    @include padding-y(2.5rem);
    @extend .px-sm-5;
    & .modal-title {
        margin-bottom: 1.5rem;
    }
    &-checkbox {
        @extend .form-check;
        text-align: left;
        & input {
            margin-top: .1rem;
        }
        & label {
            & a {
                &:hover {
                    color: darken($primary, 5%);
                }
            }
        }
    }
    & .link-small {
        &:hover {
            text-decoration: none;
        }
    }
    & .form-group-flex {
        display: flex;
        line-height: 1.2;
        & .login-checkbox {
            margin-right: 1rem;
            flex-grow: 1;
        }
    }
    & .form-submit {
        @extend .mt-5;
        @extend .mb-4;
        & .btn {
            @extend .btn-secondary;
            @extend .px-5;
            min-width: 180px;
        }
    }
    & .alert {
        text-align: left;
        @include padding-y(.75rem);
    }
    & .row-form-group {
        @extend .row;
        @extend .gx-3;
        @extend .form-group;
        & .col-form-label {
            @extend .col-12;
            @extend .col-sm-auto;
            min-width: 115px;
            @extend .text-sm-end;
        }
        & .col-form-input {
            @extend .col-12;
            @extend .col-sm;
            @include media-breakpoint-up(sm) {
                width: 72%;
            }
        }
    }
    & .form-group,
    & .form-text {
        text-align: left;
    }
}

#registration {
    & .dropdown-menu {
        max-height: 250px;
        & .form-control {
            padding: .4rem;
            min-height: initial;
            margin-bottom: .5rem;
        }
        & .dropdown-item {
            font-size: $font-size-sm;
        }
    }
}
