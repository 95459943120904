.accordion {
    &-button {
        font-weight: $font-weight-bolder;
        &:after {
            background-position: center;
        }
    }
    &-collapse {
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: $primary;
        @extend .rounded-bottom;
    }
    &-item {
        & ~ .accordion-item {
            margin-top: 1.5rem;
        }
    }
    &-button {
        @extend .rounded-top;
        &.collapsed {
            @extend .rounded-bottom;
        }
    }
    @include media-breakpoint-up(md) {
        &-button {
            font-size: 1.1rem;
            @include padding-x(2rem);
        }
        &-body {
            @include padding-x(2rem);
            & p:last-child {
                margin-bottom: 0;
            }
        }
    }
}
