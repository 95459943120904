.btn-secondary {
    &:hover {
        color: $primary;
    }
}

.btn-primary {
    &:disabled {
        background-color: $gray-300;
        border-color: $gray-300;
    }
}

%btn-delete {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$danger}'><path d='M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z'/></svg>"));
    @include back-image($size: 1.5rem);
    border: 0;
}
