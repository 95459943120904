.application {
    margin-bottom: 3rem;
    @extend .mb-md-4;
    &-title {
        @extend h3;
        margin-bottom: 2rem;
    }
    & label {
        font-weight: $font-weight-bolder;
    }
    &__add-btn {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        & .btn {
            @extend .btn-outline-secondary;
            @extend .btn-sm;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        & .application-title {
            flex-grow: 1;
            margin-bottom: 0;
        }
        & .btn-edit {
            display: inline-flex;
            align-items: center;
            @extend .btn-outline-secondary;
            & .svg-icon {
                width: 1.25rem;
                height: 1.25rem;
                @extend .me-sm-2;
            }
            & span {
                display: none;
                @extend .d-sm-inline-block;
            }
        }
    }
    &__submit {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        & .btn-submit {
            @extend .btn-primary;
            @extend .btn-lg;
            @extend .ms-sm-auto;
        }
    }
    & .file-input {
        display: flex;
        margin-top: 1.5rem;
        & .btn {
            @extend %btn-delete;
        }
    }
    &-files {
        @extend .documents;
        margin-top: .5rem;
        & li {
            display: flex;
            align-items: center;
        }
        & .file-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & .btn-delete {
            @extend %btn-delete;
            padding: .5rem;
            margin-left: .5rem;
        }
    }
    &-data {
        &__item {
            margin-bottom: 1.5rem;
        }
    }

    & .user-card {
        margin-bottom: 2rem;
    }
    & .order-registration {
        margin-top: 3rem;
        padding-top: 1.5rem;
        border-top: 1px dashed $primary;
    }
    & .form-group-row {
        @extend .row;
        @extend .gx-3;
        margin-bottom: 2rem;
    }
    & .col-form-check {
        @extend .col-12;
        @extend .col-sm-auto;
        margin-bottom: .5rem;
        & label {
            font-weight: $font-weight-bold;
            margin-right: .5rem;
        }
        & .form-check-input:not(:checked) {
            & + label {
                opacity: .6;
            }
        }
    }
    & .col-form-label {
        @extend .col-12;
        @extend .col-sm-auto;
    }
    & .col-form-input {
        @extend .col-12;
        @extend .col-sm-auto;
    }
    & .col-form-text {
        @extend .col-12;
        @extend .col-lg;
        @extend .mt-2;
        font-size: $small-font-size;
    }
    @include media-breakpoint-up(sm) {
        & .col-form-label {
            min-width: 230px;
        }
        & .col-form-input {
            width: 260px;
        }
    }
}
