.users {
    &-table {
        @extend .orders-table;

        & thead {
            white-space: nowrap;
            & tr > th:last-child {
                width: 80px;
            }
        }
    }

    &__footer {
        & .btn {
            min-width: 180px;
            margin-right: 1rem;
            margin-top: 1.5rem;
        }
        & .note {
            margin-top: 1.5rem;
            font-size: $font-size-sm;
        }
    }

    &__modal {
        @extend .py-2;
        @extend .px-sm-3;
        & .modal-body {
            & label {
                font-weight: $font-weight-bold;
            }
            & textarea {
                height: 120px;
            }
        }
    }
}

.user {
    &-status {
        font-weight: $font-weight-bolder;
        white-space: nowrap;
        &.new-user {
            color: $pink;
        }
        &.disabled-user {
            color: $gray-600;
        }
        & .status-comment {
            position: relative;
            top: -3px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            @include back-color($danger);
            font-size: .8rem;
            font-weight: $font-weight-bold;
            text-decoration: none !important;
            margin-left: .15rem;
            cursor: pointer;
            &:hover {
                background-color: darken($danger, 10%);
            }
        }
    }

    &-action {
        display: inline-block;
        text-decoration: none !important;
        & .svg-icon {
            width: 1.5rem;
            height: 1.5rem;
        }
        &:hover {
            color: $blue;
        }
        &.user-loced {
            &:hover {
                color: $gray-600;
            }
        }
        & ~ .user-action {
            margin-left: .5rem;
        }
    }

    &-card {
        display: inline-flex;
        align-items: center;
        @extend %unstyled;

        & .user-avatar {
            @extend .profile-photo;
            width: 60px;
            height: 60px;
            margin-right: 1rem;
        }
        & .user-name {
            font-weight: $font-weight-bold;
        }
        & .user-workplace {
            font-size: $font-size-sm;
            margin-top: .5rem;
        }
    }
}
