.share {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    margin-top: 2.5rem;
    &:before {
        content: '';
        display: block;
        width: 450px;
        max-width: 80%;
        height: 1px;
        background-color: $gray-500;
        position: absolute;
        top: 0;
        left: 0;
    }
}
