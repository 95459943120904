.footer {
    @extend .container-fluid;
    background-color: $primary;
    @extend .py-3;
    &__row {
        @extend .row;
        justify-content: space-between;
    }
    &__brand {
        @extend .col-12;
        @extend .col-lg-auto;
        @include padding-y(1rem);
    }
    &__col {
        @extend .col-12;
        @extend .col-sm-auto;
        @include padding-y(1rem);
        @extend .pb-lg-0;
    }
    &__copyright {
        @extend .col-12;
        @include padding-y(.6rem);
        @extend .pt-lg-0;
        & p {
            font-size: $small-font-size;
            margin-bottom: .3rem;
        }
    }
    & .address {
        font-weight: $font-weight-bolder;
        margin-bottom: 1em;
    }
    & .phone {
        margin-bottom: .5em;
        & a {
            @extend %unstyled;
        }
    }
    & .niioz {
        width: 146px;
    }
    @include media-breakpoint-down(sm) {
        font-size: .9rem;
        & .navbar-brand {
            width: auto;
            & img {
                width: 124px;
            }
        }
    }
}
