.form-group {
    margin-bottom: 1.5rem;
}

.form-control {
    &::placeholder {
        transition: all .25s ease;
    }
    &:focus {
        &::placeholder {
            opacity: 0;
        }
    }
}

.form-check-input {
    background-size: $form-check-input-width;
    &[type="radio"] {
        &:checked {
            background-color: $white;
        }
    }
}

.input-file {
    position: relative;
    overflow: hidden;
    display: block;
    & input[type="file"] {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: 100%;
        overflow: hidden;
    }
    & .btn {
        @extend .btn-primary;
    }
}
