.orders {
    &-title {
        @extend h2;
        flex: 1 0 0%;
        margin: 0;
        @include media-breakpoint-up(xl) {
            font-size: 2.3rem;
        }
    }

    &-header {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        @extend .mb-xl-6;
        & .btn {
            @extend .btn-primary;
            display: inline-flex;
            align-items: center;
            & .svg-icon {
                width: 1.5rem;
                height: 1.5rem;
                @extend .me-sm-2;
            }
            & span {
                display: none;
                @extend .d-sm-inline-block;
                @extend .me-2;
            }
            @include media-breakpoint-up(xl) {
                font-size: 1.15rem;
            }
            @include media-breakpoint-down(sm) {
                padding: .5rem;
            }
        }
    }

    &__row {
        @extend .row;
        align-items: center;
    }

    &__filter {
        @extend .col-12;
        @extend .col-xlg;
    }

    &__search {
        @extend .col-12;
        @extend .col-xlg-5;
        @extend .order-xlg-1;
    }

    &-filter {
        @extend .row;
        margin-bottom: 1rem;
        &-input {
            flex: 1 0 0%;
            width: 80%;
        }
        &__item {
            @extend .col-12;
            @extend .col-lg-6;
            @extend .col-xl-auto;
            display: flex;
            margin-bottom: 1.5rem;
            & .orders-filter-label {
                @extend .col-form-label;
                @extend .col-form-label-sm;
                margin-right: 1rem;
            }
            @include media-breakpoint-up(xl) {
                width: 430px;
            }
        }
    }

    &-table {
        @extend .table;
        @extend .table-sm;
        @extend .table-hover;
        font-size: $font-size-sm;
        & .order-status {
            font-weight: $font-weight-bolder;
            white-space: nowrap;
            &.new-order {
                color: $pink;
            }
            &.edit-order {
                color: $warning;
            }
        }
        & .order-action {
            display: inline-block;
            text-decoration: none !important;
            & .svg-icon {
                width: 1.5rem;
                height: 1.5rem;
            }
            &:hover {
                color: $blue;
            }
            & ~ .order-action {
                margin-left: .5rem;
            }
        }
        & thead {
            white-space: nowrap;
            & tr > th:last-child {
                width: 90px;
            }
        }
        & tbody {
            & tr > td:last-child {
                white-space: nowrap;
                text-align: right;
            }
        }
    }

    &-search {
        margin-bottom: 2rem;
        @extend .mb-lg-6;
        & .btn {
            @extend .btn-secondary;
            padding: .25rem .75rem;
            border-radius: $input-border-radius;
            & .svg-icon {
                width: 1.75rem;
                height: 1.75rem;
            }
        }
        @include media-breakpoint-up(xl) {
            max-width: 810px;
        }
    }
}
