.news {
    &__item {
        @extend .mb-5;
        @extend .mb-lg-6;
        & a.news-card {
            &:hover {
                & .card-title {
                    color: $primary;
                }
            }
        }
    }
    &-card {
        @extend .card;
        @extend %unstyled;
        overflow: hidden;
        & .card-image {
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        & .card-body {
            display: flex;
            flex-direction: column;
        }
        & .card-title {
            @include transition($btn-transition);
        }
    }
    &-body {
        @include back-color();
        padding: 1.5rem;
        border-radius: 0 0 $border-radius $border-radius;
        & h1 {
            @extend h3;
            margin: 0;
            @include media-breakpoint-up(xl) {
                font-size: 1.75rem;
            }
        }
    }
    &-footer {
        display: flex;
        align-items: center;
        @include padding-y(1.25rem);
        @include padding-x(.75rem);
    }
    &-date,
    &-views {
        display: flex;
        align-items: center;
        @include padding-x(.75rem);
        &:before {
            content: '';
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
    &-date {
        &:before {
            @include back-sprite(calendar);
        }
    }
    &-views {
        margin-left: 1rem;
        &:before {
            @include back-sprite(eye);
        }
    }
    &__detail {
        @include media-breakpoint-up(lg) {
            font-size: 1.1rem;
        }
    }
    @include media-breakpoint-up(lg) {
        &-card {
            flex-direction: row;
            & .card-image,
            & .card-body {
                flex: 1 0 0%;
            }
        }
        &-body {
            flex-grow: 1;
            border-radius: $border-radius $border-radius 0 $border-radius;
            padding: 2rem 3rem;
            margin-left: -3rem;
        }
        &-footer {
            @include padding-x(1.25rem);
        }
    }
}
