%board {
    background-color: $body-bg;
    @extend .p-xl-6;
    margin-bottom: 2.5rem;
    @include media-breakpoint-up(md) {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 2.5rem;
        margin-bottom: 3.5rem;
    }
}
.board {
    @extend .container;
    @extend %board;
}
.board-fluid {
    @extend .container-fluid;
    @extend %board;
    @extend .mx-md-4;
    @include media-breakpoint-up(md) {
        width: auto;
    }
}
.page-content {
    @extend .container;
    @extend .px-xl-6;
    @include media-breakpoint-up(md) {
        @include padding-x(2.5rem);
    }
}
.page-title {
    @extend h2;
    @include media-breakpoint-up(sm) {
        margin-bottom: 2rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 2.5rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: 2.3rem;
        margin-bottom: 3rem;
    }
}
.section-text {
    @include media-breakpoint-up(md) {
        font-size: 1.1rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: $font-size-lg;
    }
}
.section-button {
    text-align: center;
    padding-top: 1rem;
    & .btn {
        @extend .btn-primary;
        @extend .btn-lg;
    }
}
%section-margin {
    margin-bottom: 3rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 4rem;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 5rem;
    }
    @include media-breakpoint-up(xxl) {
        margin-bottom: 6.5rem;
    }
}
.aside {
    @extend .col-12;
    @extend .col-lg-auto;
    @extend .mb-5;
}
.content {
    @extend .col;
}
.divided {
    & li {
        margin-bottom: .5rem;
    }
}
.nav-back {
    font-size: $font-size-sm;
    margin-bottom: 1.5rem;
}
.documents {
    @extend .list-unstyled;
    & li {
        padding-left: 36px;
        @include back-sprite(folder, left top, 24px);
        min-height: 24px;
        margin-bottom: .75rem;
    }
}
%scrollbar {
    @include media-breakpoint-up(lg) {
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width: 6px;
        }
        &::-webkit-scrollbar:horizontal {
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: $border-radius;
            background-color: rgba($primary, .5);
        }
    }
}
