.modal {
    &-footer {
        padding-top: 0;
        & .btn {
            min-width: 130px;
            &.btn-submit {
                @extend .btn-primary;
                margin-left: auto;
            }
            &.btn-reset {
                @extend .btn-outline-secondary;
            }
        }
    }
}
