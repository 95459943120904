html {
    font-size: 16px;
}
body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    @include padding-y(2rem);
    @include media-breakpoint-up(sm) {
        @include padding-y(2.5rem);
        &:before {
            content: '';
            display: block;
            @include gradient-y(transparent, rgba($teal, .24));
            position: absolute;
            width: 100%;
            height: 796px;
            max-height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
    @include media-breakpoint-up(xl) {
        @include padding-y(3.5rem);
    }
}
img {
    max-width: 100%;
}
a, button {
    outline: 0 !important;
}
