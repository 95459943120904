.header {
    @extend .container-fluid;
    background-color: $primary;
}
.navbar {
    @extend .navbar-expand-xl;
    &-brand {
        display: flex;
        white-space: normal;
        flex: 0 0 auto;
        width: 315px;
        & img {
            flex: 0 0 auto;
            width: 135px;
        }
        & .brand-name {
            flex: 0 0 160px;
            font-size: .8rem;
            line-height: 1.2;
            margin-left: 1rem;
        }
    }
    &-nav {
        @extend .mx-xl-auto;
        & .nav-link {
            font-weight: $font-weight-bolder;
            @include padding-y(.75rem);
            &:hover {
                background-color: rgba($black, .05);
            }
            &.active {
                background-color: $secondary;
            }
        }
    }
    &-account {
        position: relative;
        @extend .order-xl-1;
        @extend .ps-2;
        @extend .ms-auto;
        & .btn {
            border-radius: $border-radius-sm;
            border: 0;
            padding: .25rem;
            & .svg-icon {
                width: 24px;
                height: 24px;
            }
        }
        & .dropdown-menu {
            @extend .dropdown-menu-end;
            box-shadow: $box-shadow-sm;
            border: 0;
            & .dropdown-item {
                @include padding-y(.5rem);
                &:hover {
                    background-color: transparent;
                    color: darken($primary, 15%);
                }
                &.active {
                    background-color: rgba($primary, .25);
                    color: $body-color;
                }
            }
        }
    }
    &-toggler {
        @extend .ms-3;
    }
    @include media-breakpoint-up(xl) {
        &-nav {
            & .nav-item {
                flex: 1 0 0%;
                display: flex;
                & ~ .nav-item {
                    margin-left: $navbar-padding-y;
                }
            }
            & .nav-link {
                width: 100%;
                @include flex();
                border-radius: $border-radius-sm;
                font-size: $small-font-size;
                text-align: center;
                min-height: 80px;
            }
        }
    }
    @include media-breakpoint-down(xl) {
        &-collapse {
            margin-left: -$grid-gutter-width * .5;
            margin-right: -$grid-gutter-width * .5;
        }
        &-nav {
            margin-top: 20px;
            margin-bottom: 20px;
            & .nav-link {
                @include padding-x($grid-gutter-width * .5);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        & .navbar-brand {
            width: auto;
            & img {
                width: 84px;
            }
            & .brand-name {
                display: none;
            }
        }
    }
}
