.history {
    background-color: $light;
    border-radius: $border-radius;
    padding: 1.5rem 1.5rem 1rem;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 2rem;
    &__list {
        @extend .list-unstyled;
        display: inline-flex;
        margin-bottom: 0;
    }
    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-right: 1.5rem;
        & .status-point {
            position: relative;
            @include circle(1.5rem);
            background-color: $secondary;
            @include back-image();
            margin-bottom: .25rem;
        }
        &:not(.order-complete) {
            & .status-point {
                background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$white}'/></svg>"));
            }
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: $secondary;
                position: absolute;
                top: .75rem;
                left: 0;
                transform: translateY(-50%);
                opacity: .15;
            }
            &:after {
                content: '';
                @include circle(1.5rem);
                background-color: $secondary;
                opacity: .15;
                position: absolute;
                top: 0;
                left: 100%;
            }
            &:last-child {
                padding-right: 0;
                & .status-point {
                    background-color: darken($primary, 10%);
                }
            }
        }
        & .status-name {
            font-size: $font-size-sm;
            font-weight: $font-weight-bolder;
        }
        & .status-date {
            font-size: $small-font-size;
        }
        &.order-complete {
            & .status-point {
                background-image: escape-svg($form-check-input-checked-bg-image);
            }
        }
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 2.5rem;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            height: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $gray-300;
            border-radius: $border-radius;
        }
        &__item {
            &:not(.order-complete) {
                min-width: 160px;
            }
        }
    }
}
