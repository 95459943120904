.profile {
    &-photo {
        @include circle(100px);
        border: 1px solid $secondary;
        background-image: url(../img/user-photo.svg);
        @include back-image($size: 60%);
        overflow: hidden;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &__form {
        margin-bottom: 4rem;
        &-title {
            @extend h3;
            text-align: center;
        }
        & .row-form-group {
            @extend .row;
            @extend .gx-3;
            @extend .form-group;
        }
        & .col-form-label {
            @extend .col-12;
            @extend .col-md-5;
            @extend .col-lg-3;
            @extend .text-md-end;
            white-space: nowrap;
        }
        & .col-form-input {
            @extend .col-12;
            @extend .col-md-6;
        }
        & .form-submit {
            text-align: center;
            margin-top: 2rem;
            & .btn {
                @extend .btn-primary;
                min-width: 220px;
            }
        }
        &-avatar {
            @extend .row;
            @extend .gx-4;
            justify-content: center;
            align-items: center;
            & .avatar-image {
                @extend .col-auto;
            }
            & .avatar-input {
                @extend .col-auto;
            }
        }
        @include media-breakpoint-up(md) {
            &-alert {
                width: 85%;
                @include padding-x(.3rem);
                margin-left: auto;
                margin-right: auto;
            }
        }
        @include media-breakpoint-up(lg) {
            &-alert {
                width: 50%;
            }
        }
        @include media-breakpoint-up(xl) {
            & .col-form-input,
            &-alert {
                width: 40%;
            }
            & .col-form-label {
                width: 30%;
            }
        }
    }
}
