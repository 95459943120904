.breadcrumb {
    &-item {
        & a {
            text-decoration: none !important;
            color: darken($primary, 30%);
            &:hover {
                color: $secondary;
            }
        }
    }
}
