.normative {
    @extend .page-content;
    @extend %section-margin;
    & .accordion {
        margin-bottom: 1.5rem;
    }
    & .accordion,
    & .section-title {
        @include media-breakpoint-up(sm) {
            margin-bottom: 2rem;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 2.5rem;
        }
    }
}
