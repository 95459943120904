.logistics {
    @extend .page-content;
    @extend %section-margin;
    @extend .section-text;
    & .section-title {
        @include media-breakpoint-up(xl) {
            margin-bottom: 2rem;
        }
    }
}
