.list-schedule {
    @extend .list-unstyled;
    @extend .divided;
    & li span {
        &:after {
            content: '—';
            font-weight: $font-weight-bold;
            @extend .mx-3;
        }
    }
}

.list-procedure {
    @extend .list-unstyled;
    & li {
        display: flex;
        align-items: flex-start;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        & span {
            flex-shrink: 0;
            @include back-color();
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
            text-align: center;
            @extend .rounded;
            padding: .5rem 1rem;
            min-width: 140px;
            margin-right: 1rem;
            margin-top: -.5rem;
            margin-bottom: .5rem;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
}

.warning {
    position: relative;
    color: $orange;
    padding-left: 1.5rem;
    &:before {
        content: '';
        display: block;
        width: .4rem;
        height: 100%;
        background-color: $orange;
        border-radius: $border-radius-sm;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.check-list {
    @extend .list-unstyled;
    @extend .divided;
    & li {
        padding-left: 28px;
        @include back-sprite(check-circle, left top 4px, 16px);
    }
}
